.card{
    border: none;
    box-shadow: 0px 0px 5px #ccc;
}
.w_100{
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.tittle{
    color:#212830;
}