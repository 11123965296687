.modal-content {
  border-width: 0;
}

.modal-backdrop {
  background-color: darken($gray-900, 5%);

  &.show { opacity: .8; }
}

.modal {
  --bs-modal-width: 650px;
}